/* tslint:disable */
/* eslint-disable */
/**
 * Portal API Glommen Mjøsen Skog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CanSignAccountWithPropertyRightOfUseInputRest } from '../model';
// @ts-ignore
import { CanSignAccountWithPropertyRightOfUseOutput } from '../model';
// @ts-ignore
import { CancelDelegationInput } from '../model';
// @ts-ignore
import { CancelDelegationOutput } from '../model';
// @ts-ignore
import { CancelRightOfUseInput } from '../model';
// @ts-ignore
import { CancelRightOfUseOutput } from '../model';
// @ts-ignore
import { CheckCrmAccountByOrgNumberInput } from '../model';
// @ts-ignore
import { CheckCrmAccountByOrgNumberOutput } from '../model';
// @ts-ignore
import { CreatePowerOfAttorneyInput } from '../model';
// @ts-ignore
import { CreatePowerOfAttorneyOutput } from '../model';
// @ts-ignore
import { CreateTemporaryPowerOfAttorneyContactInput } from '../model';
// @ts-ignore
import { CreateTemporaryPowerOfAttorneyContactOutput } from '../model';
// @ts-ignore
import { EssentialPersonInfoResponse } from '../model';
// @ts-ignore
import { GetForestOwnerContactByPropertyInfoInput } from '../model';
// @ts-ignore
import { GetForestOwnerContactByPropertyInfoOutput } from '../model';
// @ts-ignore
import { GetOwnedPropertiesAccountInput } from '../model';
// @ts-ignore
import { GetOwnedPropertiesAccountOutput } from '../model';
// @ts-ignore
import { GetOwnedPropertiesContactOutput } from '../model';
// @ts-ignore
import { GetPersonInfoFromPersonalNumberLastNameDto } from '../model';
// @ts-ignore
import { GetPropertiesWhereUserCanGiveAccountUsageRightInput } from '../model';
// @ts-ignore
import { GetPropertiesWhereUserCanGiveAccountUsageRightOutput } from '../model';
// @ts-ignore
import { GetPropertyRightOfUseFromPropertyIdOutput } from '../model';
// @ts-ignore
import { GetRightOfUseListInputRest } from '../model';
// @ts-ignore
import { GetRightOfUseListOutput } from '../model';
// @ts-ignore
import { GetRightToSignListInput } from '../model';
// @ts-ignore
import { GetRightToSignListOutput } from '../model';
// @ts-ignore
import { GetSignedDelegationDocumentLinkOutput } from '../model';
// @ts-ignore
import { GetUnsignedDocumentOutput } from '../model';
// @ts-ignore
import { NewGetPropertiesWithRightOfUseAccountInput } from '../model';
// @ts-ignore
import { NewGetPropertiesWithRightOfUseAccountOutput } from '../model';
// @ts-ignore
import { RequestPowerOfAttorneyInputRest } from '../model';
// @ts-ignore
import { StopCancellationRightOfUseInput } from '../model';
// @ts-ignore
import { StopCancellationRightOfUseOutput } from '../model';
// @ts-ignore
import { UpsertContactByOrgNumberInput } from '../model';
// @ts-ignore
import { UpsertContactByOrgNumberOutput } from '../model';
// @ts-ignore
import { UpsertContactByPropertyInput } from '../model';
// @ts-ignore
import { UpsertContactByPropertyOutput } from '../model';
// @ts-ignore
import { VerifyContactByNameAndSsnInput } from '../model';
// @ts-ignore
import { VerifyContactByNameAndSsnOutput } from '../model';
/**
 * RightOfUseApi - axios parameter creator
 * @export
 */
export const RightOfUseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Can sign RoU for property
         * @param {CanSignAccountWithPropertyRightOfUseInputRest} [canSignAccountWithPropertyRightOfUseInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCanSignRightOfUseForProperty: async (canSignAccountWithPropertyRightOfUseInputRest?: CanSignAccountWithPropertyRightOfUseInputRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/can-sign-right-of-use-for-property`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canSignAccountWithPropertyRightOfUseInputRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start cancel process of RoU
         * @param {CancelRightOfUseInput} [cancelRightOfUseInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCancelRightOfUse: async (cancelRightOfUseInput?: CancelRightOfUseInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/cancel-right-of-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelRightOfUseInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check CRM account by organization no.
         * @param {CheckCrmAccountByOrgNumberInput} [checkCrmAccountByOrgNumberInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCheckCrmAccountByOrgNumber: async (checkCrmAccountByOrgNumberInput?: CheckCrmAccountByOrgNumberInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/check-crm-account-by-orgNo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkCrmAccountByOrgNumberInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates Power Of Attorney. For the use case when the logged in user wishes to give PowerOfAttorney of a property to another person
         * @param {CreatePowerOfAttorneyInput} [createPowerOfAttorneyInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCreatePowerOfAttorney: async (createPowerOfAttorneyInput?: CreatePowerOfAttorneyInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/create-power-of-attorney`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPowerOfAttorneyInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create temporary PoA contact
         * @param {CreateTemporaryPowerOfAttorneyContactInput} [createTemporaryPowerOfAttorneyContactInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCreateTemporaryPowerOfAttorneyContact: async (createTemporaryPowerOfAttorneyContactInput?: CreateTemporaryPowerOfAttorneyContactInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/create-temporary-power-of-attorney-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTemporaryPowerOfAttorneyContactInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete PoA
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseDeletePowerOfAttorney: async (delegationRefCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'delegationRefCode' is not null or undefined
            assertParamExists('rightOfUseDeletePowerOfAttorney', 'delegationRefCode', delegationRefCode)
            const localVarPath = `/api/v1/RightOfUse/delete-power-of-attorney/{delegationRefCode}`
                .replace(`{${"delegationRefCode"}}`, encodeURIComponent(String(delegationRefCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes RightOfUse for a property.
         * @param {string} delegationRefCode 
         * @param {CancelDelegationInput} [cancelDelegationInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseDeleteRightOfUse: async (delegationRefCode: string, cancelDelegationInput?: CancelDelegationInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'delegationRefCode' is not null or undefined
            assertParamExists('rightOfUseDeleteRightOfUse', 'delegationRefCode', delegationRefCode)
            const localVarPath = `/api/v1/RightOfUse/delete-right-of-use/{delegationRefCode}`
                .replace(`{${"delegationRefCode"}}`, encodeURIComponent(String(delegationRefCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelDelegationInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contact by organization number
         * @param {UpsertContactByOrgNumberInput} [upsertContactByOrgNumberInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetContactByOrganizationAction: async (upsertContactByOrgNumberInput?: UpsertContactByOrgNumberInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/upsert-contact-by-organization-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertContactByOrgNumberInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contact by property
         * @param {UpsertContactByPropertyInput} [upsertContactByPropertyInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetContactByPropertyAction: async (upsertContactByPropertyInput?: UpsertContactByPropertyInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/upsert-contact-by-property-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertContactByPropertyInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns contact information stored in Crm for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetEssentialPersonInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-essential-person-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of forest owner for a property
         * @param {GetForestOwnerContactByPropertyInfoInput} [getForestOwnerContactByPropertyInfoInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetForestOwnerContactByPropertyInfo: async (getForestOwnerContactByPropertyInfoInput?: GetForestOwnerContactByPropertyInfoInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-forest-owners-by-property-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getForestOwnerContactByPropertyInfoInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm    Finished on Backend side
         * @param {GetOwnedPropertiesAccountInput} [getOwnedPropertiesAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetOwnedPropertiesAccount: async (getOwnedPropertiesAccountInput?: GetOwnedPropertiesAccountInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-owned-properties-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOwnedPropertiesAccountInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm
         * @param {GetOwnedPropertiesAccountInput} [getOwnedPropertiesAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetOwnedPropertiesAccountWithOutRightOfUse: async (getOwnedPropertiesAccountInput?: GetOwnedPropertiesAccountInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-owned-properties-account-without-right-of-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOwnedPropertiesAccountInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the properties owned by the person. Searches first for the info in Matrikkel, then adds information stored in Crm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetOwnedPropertiesContact: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-owned-properties-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns contact information stored in Crm from Social Security Number (SSN) and last name
         * @param {GetPersonInfoFromPersonalNumberLastNameDto} [getPersonInfoFromPersonalNumberLastNameDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPersonFromSsnLastName: async (getPersonInfoFromPersonalNumberLastNameDto?: GetPersonInfoFromPersonalNumberLastNameDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-contact-by-ssn-and-last-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPersonInfoFromPersonalNumberLastNameDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify user by population register and find user in crm
         * @param {VerifyContactByNameAndSsnInput} [verifyContactByNameAndSsnInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPersonFromSsnLastNameAction: async (verifyContactByNameAndSsnInput?: VerifyContactByNameAndSsnInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-contact-by-ssn-and-last-name-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyContactByNameAndSsnInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the properties where the logged in person can give an account usage right
         * @param {GetPropertiesWhereUserCanGiveAccountUsageRightInput} [getPropertiesWhereUserCanGiveAccountUsageRightInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertiesWhereUserCanGiveAccountUsageRight: async (getPropertiesWhereUserCanGiveAccountUsageRightInput?: GetPropertiesWhereUserCanGiveAccountUsageRightInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-properties-where-user-can-give-account-usage-right`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPropertiesWhereUserCanGiveAccountUsageRightInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Takes the accountId of an organization and returns the properties where the organization have right of use
         * @param {NewGetPropertiesWithRightOfUseAccountInput} [newGetPropertiesWithRightOfUseAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertiesWithRightOfUseAccount: async (newGetPropertiesWithRightOfUseAccountInput?: NewGetPropertiesWithRightOfUseAccountInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-properties-with-right-of-use-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newGetPropertiesWithRightOfUseAccountInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertyInfo: async (propertyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('rightOfUseGetPropertyInfo', 'propertyId', propertyId)
            const localVarPath = `/api/v1/RightOfUse/get-property-info/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns information about the right of use for a property
         * @param {GetRightOfUseListInputRest} [getRightOfUseListInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertyRightOfUse: async (getRightOfUseListInputRest?: GetRightOfUseListInputRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-property-right-of-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getRightOfUseListInputRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list over the people who have right to sign for a property
         * @param {GetRightToSignListInput} [getRightToSignListInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertyRightToSign: async (getRightToSignListInput?: GetRightToSignListInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-property-right-to-sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getRightToSignListInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get signed delegation document
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetSignedDelegationDocumentLink: async (delegationRefCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'delegationRefCode' is not null or undefined
            assertParamExists('rightOfUseGetSignedDelegationDocumentLink', 'delegationRefCode', delegationRefCode)
            const localVarPath = `/api/v1/RightOfUse/get-signed-delegation-document-link/{delegationRefCode}`
                .replace(`{${"delegationRefCode"}}`, encodeURIComponent(String(delegationRefCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Getting documents sent to Verified by logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetUnsignedDocuments: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/get-unsigned-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary For instances where the logged in user wishes to request PowerOfAttorney for a property owned and controlled by someone else
         * @param {RequestPowerOfAttorneyInputRest} [requestPowerOfAttorneyInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseRequestPowerOfAttorney: async (requestPowerOfAttorneyInputRest?: RequestPowerOfAttorneyInputRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/request-power-of-attorney`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPowerOfAttorneyInputRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Automatically sets this if the 90% rule is ok
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseSetRightOfUseAndPowerOfAttorneyAutomatically: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/set-rightOfUse-and-powerOfAttorney-automatically`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop already started cancel process of RoU
         * @param {StopCancellationRightOfUseInput} [stopCancellationRightOfUseInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseStopCancellationRightOfUse: async (stopCancellationRightOfUseInput?: StopCancellationRightOfUseInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/RightOfUse/stop-cancellation-right-of-use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stopCancellationRightOfUseInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RightOfUseApi - functional programming interface
 * @export
 */
export const RightOfUseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RightOfUseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Can sign RoU for property
         * @param {CanSignAccountWithPropertyRightOfUseInputRest} [canSignAccountWithPropertyRightOfUseInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseCanSignRightOfUseForProperty(canSignAccountWithPropertyRightOfUseInputRest?: CanSignAccountWithPropertyRightOfUseInputRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanSignAccountWithPropertyRightOfUseOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseCanSignRightOfUseForProperty(canSignAccountWithPropertyRightOfUseInputRest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseCanSignRightOfUseForProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Start cancel process of RoU
         * @param {CancelRightOfUseInput} [cancelRightOfUseInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseCancelRightOfUse(cancelRightOfUseInput?: CancelRightOfUseInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelRightOfUseOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseCancelRightOfUse(cancelRightOfUseInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseCancelRightOfUse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Check CRM account by organization no.
         * @param {CheckCrmAccountByOrgNumberInput} [checkCrmAccountByOrgNumberInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseCheckCrmAccountByOrgNumber(checkCrmAccountByOrgNumberInput?: CheckCrmAccountByOrgNumberInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckCrmAccountByOrgNumberOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseCheckCrmAccountByOrgNumber(checkCrmAccountByOrgNumberInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseCheckCrmAccountByOrgNumber']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates Power Of Attorney. For the use case when the logged in user wishes to give PowerOfAttorney of a property to another person
         * @param {CreatePowerOfAttorneyInput} [createPowerOfAttorneyInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseCreatePowerOfAttorney(createPowerOfAttorneyInput?: CreatePowerOfAttorneyInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePowerOfAttorneyOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseCreatePowerOfAttorney(createPowerOfAttorneyInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseCreatePowerOfAttorney']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create temporary PoA contact
         * @param {CreateTemporaryPowerOfAttorneyContactInput} [createTemporaryPowerOfAttorneyContactInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseCreateTemporaryPowerOfAttorneyContact(createTemporaryPowerOfAttorneyContactInput?: CreateTemporaryPowerOfAttorneyContactInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTemporaryPowerOfAttorneyContactOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseCreateTemporaryPowerOfAttorneyContact(createTemporaryPowerOfAttorneyContactInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseCreateTemporaryPowerOfAttorneyContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete PoA
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseDeletePowerOfAttorney(delegationRefCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelDelegationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseDeletePowerOfAttorney(delegationRefCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseDeletePowerOfAttorney']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes RightOfUse for a property.
         * @param {string} delegationRefCode 
         * @param {CancelDelegationInput} [cancelDelegationInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseDeleteRightOfUse(delegationRefCode: string, cancelDelegationInput?: CancelDelegationInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelDelegationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseDeleteRightOfUse(delegationRefCode, cancelDelegationInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseDeleteRightOfUse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contact by organization number
         * @param {UpsertContactByOrgNumberInput} [upsertContactByOrgNumberInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetContactByOrganizationAction(upsertContactByOrgNumberInput?: UpsertContactByOrgNumberInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertContactByOrgNumberOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetContactByOrganizationAction(upsertContactByOrgNumberInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetContactByOrganizationAction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contact by property
         * @param {UpsertContactByPropertyInput} [upsertContactByPropertyInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetContactByPropertyAction(upsertContactByPropertyInput?: UpsertContactByPropertyInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertContactByPropertyOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetContactByPropertyAction(upsertContactByPropertyInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetContactByPropertyAction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns contact information stored in Crm for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetEssentialPersonInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EssentialPersonInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetEssentialPersonInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetEssentialPersonInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of forest owner for a property
         * @param {GetForestOwnerContactByPropertyInfoInput} [getForestOwnerContactByPropertyInfoInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetForestOwnerContactByPropertyInfo(getForestOwnerContactByPropertyInfoInput?: GetForestOwnerContactByPropertyInfoInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetForestOwnerContactByPropertyInfoOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetForestOwnerContactByPropertyInfo(getForestOwnerContactByPropertyInfoInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetForestOwnerContactByPropertyInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm    Finished on Backend side
         * @param {GetOwnedPropertiesAccountInput} [getOwnedPropertiesAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetOwnedPropertiesAccount(getOwnedPropertiesAccountInput?: GetOwnedPropertiesAccountInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOwnedPropertiesAccountOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetOwnedPropertiesAccount(getOwnedPropertiesAccountInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetOwnedPropertiesAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm
         * @param {GetOwnedPropertiesAccountInput} [getOwnedPropertiesAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetOwnedPropertiesAccountWithOutRightOfUse(getOwnedPropertiesAccountInput?: GetOwnedPropertiesAccountInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOwnedPropertiesAccountOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetOwnedPropertiesAccountWithOutRightOfUse(getOwnedPropertiesAccountInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetOwnedPropertiesAccountWithOutRightOfUse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the properties owned by the person. Searches first for the info in Matrikkel, then adds information stored in Crm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetOwnedPropertiesContact(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOwnedPropertiesContactOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetOwnedPropertiesContact(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetOwnedPropertiesContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns contact information stored in Crm from Social Security Number (SSN) and last name
         * @param {GetPersonInfoFromPersonalNumberLastNameDto} [getPersonInfoFromPersonalNumberLastNameDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetPersonFromSsnLastName(getPersonInfoFromPersonalNumberLastNameDto?: GetPersonInfoFromPersonalNumberLastNameDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EssentialPersonInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetPersonFromSsnLastName(getPersonInfoFromPersonalNumberLastNameDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetPersonFromSsnLastName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Verify user by population register and find user in crm
         * @param {VerifyContactByNameAndSsnInput} [verifyContactByNameAndSsnInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetPersonFromSsnLastNameAction(verifyContactByNameAndSsnInput?: VerifyContactByNameAndSsnInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyContactByNameAndSsnOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetPersonFromSsnLastNameAction(verifyContactByNameAndSsnInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetPersonFromSsnLastNameAction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns the properties where the logged in person can give an account usage right
         * @param {GetPropertiesWhereUserCanGiveAccountUsageRightInput} [getPropertiesWhereUserCanGiveAccountUsageRightInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetPropertiesWhereUserCanGiveAccountUsageRight(getPropertiesWhereUserCanGiveAccountUsageRightInput?: GetPropertiesWhereUserCanGiveAccountUsageRightInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPropertiesWhereUserCanGiveAccountUsageRightOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetPropertiesWhereUserCanGiveAccountUsageRight(getPropertiesWhereUserCanGiveAccountUsageRightInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetPropertiesWhereUserCanGiveAccountUsageRight']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Takes the accountId of an organization and returns the properties where the organization have right of use
         * @param {NewGetPropertiesWithRightOfUseAccountInput} [newGetPropertiesWithRightOfUseAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetPropertiesWithRightOfUseAccount(newGetPropertiesWithRightOfUseAccountInput?: NewGetPropertiesWithRightOfUseAccountInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewGetPropertiesWithRightOfUseAccountOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetPropertiesWithRightOfUseAccount(newGetPropertiesWithRightOfUseAccountInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetPropertiesWithRightOfUseAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetPropertyInfo(propertyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPropertyRightOfUseFromPropertyIdOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetPropertyInfo(propertyId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetPropertyInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns information about the right of use for a property
         * @param {GetRightOfUseListInputRest} [getRightOfUseListInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetPropertyRightOfUse(getRightOfUseListInputRest?: GetRightOfUseListInputRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRightOfUseListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetPropertyRightOfUse(getRightOfUseListInputRest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetPropertyRightOfUse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns the list over the people who have right to sign for a property
         * @param {GetRightToSignListInput} [getRightToSignListInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetPropertyRightToSign(getRightToSignListInput?: GetRightToSignListInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRightToSignListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetPropertyRightToSign(getRightToSignListInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetPropertyRightToSign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get signed delegation document
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetSignedDelegationDocumentLink(delegationRefCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignedDelegationDocumentLinkOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetSignedDelegationDocumentLink(delegationRefCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetSignedDelegationDocumentLink']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Getting documents sent to Verified by logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseGetUnsignedDocuments(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUnsignedDocumentOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseGetUnsignedDocuments(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseGetUnsignedDocuments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary For instances where the logged in user wishes to request PowerOfAttorney for a property owned and controlled by someone else
         * @param {RequestPowerOfAttorneyInputRest} [requestPowerOfAttorneyInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseRequestPowerOfAttorney(requestPowerOfAttorneyInputRest?: RequestPowerOfAttorneyInputRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePowerOfAttorneyOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseRequestPowerOfAttorney(requestPowerOfAttorneyInputRest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseRequestPowerOfAttorney']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Automatically sets this if the 90% rule is ok
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseSetRightOfUseAndPowerOfAttorneyAutomatically(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseSetRightOfUseAndPowerOfAttorneyAutomatically(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseSetRightOfUseAndPowerOfAttorneyAutomatically']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Stop already started cancel process of RoU
         * @param {StopCancellationRightOfUseInput} [stopCancellationRightOfUseInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rightOfUseStopCancellationRightOfUse(stopCancellationRightOfUseInput?: StopCancellationRightOfUseInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopCancellationRightOfUseOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rightOfUseStopCancellationRightOfUse(stopCancellationRightOfUseInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RightOfUseApi.rightOfUseStopCancellationRightOfUse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RightOfUseApi - factory interface
 * @export
 */
export const RightOfUseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RightOfUseApiFp(configuration)
    return {
        /**
         * 
         * @summary Can sign RoU for property
         * @param {CanSignAccountWithPropertyRightOfUseInputRest} [canSignAccountWithPropertyRightOfUseInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCanSignRightOfUseForProperty(canSignAccountWithPropertyRightOfUseInputRest?: CanSignAccountWithPropertyRightOfUseInputRest, options?: any): AxiosPromise<CanSignAccountWithPropertyRightOfUseOutput> {
            return localVarFp.rightOfUseCanSignRightOfUseForProperty(canSignAccountWithPropertyRightOfUseInputRest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start cancel process of RoU
         * @param {CancelRightOfUseInput} [cancelRightOfUseInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCancelRightOfUse(cancelRightOfUseInput?: CancelRightOfUseInput, options?: any): AxiosPromise<CancelRightOfUseOutput> {
            return localVarFp.rightOfUseCancelRightOfUse(cancelRightOfUseInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check CRM account by organization no.
         * @param {CheckCrmAccountByOrgNumberInput} [checkCrmAccountByOrgNumberInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCheckCrmAccountByOrgNumber(checkCrmAccountByOrgNumberInput?: CheckCrmAccountByOrgNumberInput, options?: any): AxiosPromise<CheckCrmAccountByOrgNumberOutput> {
            return localVarFp.rightOfUseCheckCrmAccountByOrgNumber(checkCrmAccountByOrgNumberInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates Power Of Attorney. For the use case when the logged in user wishes to give PowerOfAttorney of a property to another person
         * @param {CreatePowerOfAttorneyInput} [createPowerOfAttorneyInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCreatePowerOfAttorney(createPowerOfAttorneyInput?: CreatePowerOfAttorneyInput, options?: any): AxiosPromise<CreatePowerOfAttorneyOutput> {
            return localVarFp.rightOfUseCreatePowerOfAttorney(createPowerOfAttorneyInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create temporary PoA contact
         * @param {CreateTemporaryPowerOfAttorneyContactInput} [createTemporaryPowerOfAttorneyContactInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseCreateTemporaryPowerOfAttorneyContact(createTemporaryPowerOfAttorneyContactInput?: CreateTemporaryPowerOfAttorneyContactInput, options?: any): AxiosPromise<CreateTemporaryPowerOfAttorneyContactOutput> {
            return localVarFp.rightOfUseCreateTemporaryPowerOfAttorneyContact(createTemporaryPowerOfAttorneyContactInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete PoA
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseDeletePowerOfAttorney(delegationRefCode: string, options?: any): AxiosPromise<CancelDelegationOutput> {
            return localVarFp.rightOfUseDeletePowerOfAttorney(delegationRefCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes RightOfUse for a property.
         * @param {string} delegationRefCode 
         * @param {CancelDelegationInput} [cancelDelegationInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseDeleteRightOfUse(delegationRefCode: string, cancelDelegationInput?: CancelDelegationInput, options?: any): AxiosPromise<CancelDelegationOutput> {
            return localVarFp.rightOfUseDeleteRightOfUse(delegationRefCode, cancelDelegationInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contact by organization number
         * @param {UpsertContactByOrgNumberInput} [upsertContactByOrgNumberInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetContactByOrganizationAction(upsertContactByOrgNumberInput?: UpsertContactByOrgNumberInput, options?: any): AxiosPromise<UpsertContactByOrgNumberOutput> {
            return localVarFp.rightOfUseGetContactByOrganizationAction(upsertContactByOrgNumberInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contact by property
         * @param {UpsertContactByPropertyInput} [upsertContactByPropertyInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetContactByPropertyAction(upsertContactByPropertyInput?: UpsertContactByPropertyInput, options?: any): AxiosPromise<UpsertContactByPropertyOutput> {
            return localVarFp.rightOfUseGetContactByPropertyAction(upsertContactByPropertyInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns contact information stored in Crm for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetEssentialPersonInfo(options?: any): AxiosPromise<EssentialPersonInfoResponse> {
            return localVarFp.rightOfUseGetEssentialPersonInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of forest owner for a property
         * @param {GetForestOwnerContactByPropertyInfoInput} [getForestOwnerContactByPropertyInfoInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetForestOwnerContactByPropertyInfo(getForestOwnerContactByPropertyInfoInput?: GetForestOwnerContactByPropertyInfoInput, options?: any): AxiosPromise<GetForestOwnerContactByPropertyInfoOutput> {
            return localVarFp.rightOfUseGetForestOwnerContactByPropertyInfo(getForestOwnerContactByPropertyInfoInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm    Finished on Backend side
         * @param {GetOwnedPropertiesAccountInput} [getOwnedPropertiesAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetOwnedPropertiesAccount(getOwnedPropertiesAccountInput?: GetOwnedPropertiesAccountInput, options?: any): AxiosPromise<GetOwnedPropertiesAccountOutput> {
            return localVarFp.rightOfUseGetOwnedPropertiesAccount(getOwnedPropertiesAccountInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm
         * @param {GetOwnedPropertiesAccountInput} [getOwnedPropertiesAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetOwnedPropertiesAccountWithOutRightOfUse(getOwnedPropertiesAccountInput?: GetOwnedPropertiesAccountInput, options?: any): AxiosPromise<GetOwnedPropertiesAccountOutput> {
            return localVarFp.rightOfUseGetOwnedPropertiesAccountWithOutRightOfUse(getOwnedPropertiesAccountInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the properties owned by the person. Searches first for the info in Matrikkel, then adds information stored in Crm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetOwnedPropertiesContact(options?: any): AxiosPromise<GetOwnedPropertiesContactOutput> {
            return localVarFp.rightOfUseGetOwnedPropertiesContact(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns contact information stored in Crm from Social Security Number (SSN) and last name
         * @param {GetPersonInfoFromPersonalNumberLastNameDto} [getPersonInfoFromPersonalNumberLastNameDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPersonFromSsnLastName(getPersonInfoFromPersonalNumberLastNameDto?: GetPersonInfoFromPersonalNumberLastNameDto, options?: any): AxiosPromise<EssentialPersonInfoResponse> {
            return localVarFp.rightOfUseGetPersonFromSsnLastName(getPersonInfoFromPersonalNumberLastNameDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify user by population register and find user in crm
         * @param {VerifyContactByNameAndSsnInput} [verifyContactByNameAndSsnInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPersonFromSsnLastNameAction(verifyContactByNameAndSsnInput?: VerifyContactByNameAndSsnInput, options?: any): AxiosPromise<VerifyContactByNameAndSsnOutput> {
            return localVarFp.rightOfUseGetPersonFromSsnLastNameAction(verifyContactByNameAndSsnInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the properties where the logged in person can give an account usage right
         * @param {GetPropertiesWhereUserCanGiveAccountUsageRightInput} [getPropertiesWhereUserCanGiveAccountUsageRightInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertiesWhereUserCanGiveAccountUsageRight(getPropertiesWhereUserCanGiveAccountUsageRightInput?: GetPropertiesWhereUserCanGiveAccountUsageRightInput, options?: any): AxiosPromise<GetPropertiesWhereUserCanGiveAccountUsageRightOutput> {
            return localVarFp.rightOfUseGetPropertiesWhereUserCanGiveAccountUsageRight(getPropertiesWhereUserCanGiveAccountUsageRightInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Takes the accountId of an organization and returns the properties where the organization have right of use
         * @param {NewGetPropertiesWithRightOfUseAccountInput} [newGetPropertiesWithRightOfUseAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertiesWithRightOfUseAccount(newGetPropertiesWithRightOfUseAccountInput?: NewGetPropertiesWithRightOfUseAccountInput, options?: any): AxiosPromise<NewGetPropertiesWithRightOfUseAccountOutput> {
            return localVarFp.rightOfUseGetPropertiesWithRightOfUseAccount(newGetPropertiesWithRightOfUseAccountInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm
         * @param {string} propertyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertyInfo(propertyId: string, options?: any): AxiosPromise<GetPropertyRightOfUseFromPropertyIdOutput> {
            return localVarFp.rightOfUseGetPropertyInfo(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns information about the right of use for a property
         * @param {GetRightOfUseListInputRest} [getRightOfUseListInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertyRightOfUse(getRightOfUseListInputRest?: GetRightOfUseListInputRest, options?: any): AxiosPromise<GetRightOfUseListOutput> {
            return localVarFp.rightOfUseGetPropertyRightOfUse(getRightOfUseListInputRest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list over the people who have right to sign for a property
         * @param {GetRightToSignListInput} [getRightToSignListInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetPropertyRightToSign(getRightToSignListInput?: GetRightToSignListInput, options?: any): AxiosPromise<GetRightToSignListOutput> {
            return localVarFp.rightOfUseGetPropertyRightToSign(getRightToSignListInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get signed delegation document
         * @param {string} delegationRefCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetSignedDelegationDocumentLink(delegationRefCode: string, options?: any): AxiosPromise<GetSignedDelegationDocumentLinkOutput> {
            return localVarFp.rightOfUseGetSignedDelegationDocumentLink(delegationRefCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Getting documents sent to Verified by logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseGetUnsignedDocuments(options?: any): AxiosPromise<GetUnsignedDocumentOutput> {
            return localVarFp.rightOfUseGetUnsignedDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary For instances where the logged in user wishes to request PowerOfAttorney for a property owned and controlled by someone else
         * @param {RequestPowerOfAttorneyInputRest} [requestPowerOfAttorneyInputRest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseRequestPowerOfAttorney(requestPowerOfAttorneyInputRest?: RequestPowerOfAttorneyInputRest, options?: any): AxiosPromise<CreatePowerOfAttorneyOutput> {
            return localVarFp.rightOfUseRequestPowerOfAttorney(requestPowerOfAttorneyInputRest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Automatically sets this if the 90% rule is ok
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseSetRightOfUseAndPowerOfAttorneyAutomatically(options?: any): AxiosPromise<object> {
            return localVarFp.rightOfUseSetRightOfUseAndPowerOfAttorneyAutomatically(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop already started cancel process of RoU
         * @param {StopCancellationRightOfUseInput} [stopCancellationRightOfUseInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rightOfUseStopCancellationRightOfUse(stopCancellationRightOfUseInput?: StopCancellationRightOfUseInput, options?: any): AxiosPromise<StopCancellationRightOfUseOutput> {
            return localVarFp.rightOfUseStopCancellationRightOfUse(stopCancellationRightOfUseInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RightOfUseApi - object-oriented interface
 * @export
 * @class RightOfUseApi
 * @extends {BaseAPI}
 */
export class RightOfUseApi extends BaseAPI {
    /**
     * 
     * @summary Can sign RoU for property
     * @param {CanSignAccountWithPropertyRightOfUseInputRest} [canSignAccountWithPropertyRightOfUseInputRest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseCanSignRightOfUseForProperty(canSignAccountWithPropertyRightOfUseInputRest?: CanSignAccountWithPropertyRightOfUseInputRest, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseCanSignRightOfUseForProperty(canSignAccountWithPropertyRightOfUseInputRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start cancel process of RoU
     * @param {CancelRightOfUseInput} [cancelRightOfUseInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseCancelRightOfUse(cancelRightOfUseInput?: CancelRightOfUseInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseCancelRightOfUse(cancelRightOfUseInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check CRM account by organization no.
     * @param {CheckCrmAccountByOrgNumberInput} [checkCrmAccountByOrgNumberInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseCheckCrmAccountByOrgNumber(checkCrmAccountByOrgNumberInput?: CheckCrmAccountByOrgNumberInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseCheckCrmAccountByOrgNumber(checkCrmAccountByOrgNumberInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates Power Of Attorney. For the use case when the logged in user wishes to give PowerOfAttorney of a property to another person
     * @param {CreatePowerOfAttorneyInput} [createPowerOfAttorneyInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseCreatePowerOfAttorney(createPowerOfAttorneyInput?: CreatePowerOfAttorneyInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseCreatePowerOfAttorney(createPowerOfAttorneyInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create temporary PoA contact
     * @param {CreateTemporaryPowerOfAttorneyContactInput} [createTemporaryPowerOfAttorneyContactInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseCreateTemporaryPowerOfAttorneyContact(createTemporaryPowerOfAttorneyContactInput?: CreateTemporaryPowerOfAttorneyContactInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseCreateTemporaryPowerOfAttorneyContact(createTemporaryPowerOfAttorneyContactInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete PoA
     * @param {string} delegationRefCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseDeletePowerOfAttorney(delegationRefCode: string, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseDeletePowerOfAttorney(delegationRefCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes RightOfUse for a property.
     * @param {string} delegationRefCode 
     * @param {CancelDelegationInput} [cancelDelegationInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseDeleteRightOfUse(delegationRefCode: string, cancelDelegationInput?: CancelDelegationInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseDeleteRightOfUse(delegationRefCode, cancelDelegationInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contact by organization number
     * @param {UpsertContactByOrgNumberInput} [upsertContactByOrgNumberInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetContactByOrganizationAction(upsertContactByOrgNumberInput?: UpsertContactByOrgNumberInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetContactByOrganizationAction(upsertContactByOrgNumberInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contact by property
     * @param {UpsertContactByPropertyInput} [upsertContactByPropertyInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetContactByPropertyAction(upsertContactByPropertyInput?: UpsertContactByPropertyInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetContactByPropertyAction(upsertContactByPropertyInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns contact information stored in Crm for the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetEssentialPersonInfo(options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetEssentialPersonInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of forest owner for a property
     * @param {GetForestOwnerContactByPropertyInfoInput} [getForestOwnerContactByPropertyInfoInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetForestOwnerContactByPropertyInfo(getForestOwnerContactByPropertyInfoInput?: GetForestOwnerContactByPropertyInfoInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetForestOwnerContactByPropertyInfo(getForestOwnerContactByPropertyInfoInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm    Finished on Backend side
     * @param {GetOwnedPropertiesAccountInput} [getOwnedPropertiesAccountInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetOwnedPropertiesAccount(getOwnedPropertiesAccountInput?: GetOwnedPropertiesAccountInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetOwnedPropertiesAccount(getOwnedPropertiesAccountInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm
     * @param {GetOwnedPropertiesAccountInput} [getOwnedPropertiesAccountInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetOwnedPropertiesAccountWithOutRightOfUse(getOwnedPropertiesAccountInput?: GetOwnedPropertiesAccountInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetOwnedPropertiesAccountWithOutRightOfUse(getOwnedPropertiesAccountInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the properties owned by the person. Searches first for the info in Matrikkel, then adds information stored in Crm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetOwnedPropertiesContact(options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetOwnedPropertiesContact(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns contact information stored in Crm from Social Security Number (SSN) and last name
     * @param {GetPersonInfoFromPersonalNumberLastNameDto} [getPersonInfoFromPersonalNumberLastNameDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetPersonFromSsnLastName(getPersonInfoFromPersonalNumberLastNameDto?: GetPersonInfoFromPersonalNumberLastNameDto, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetPersonFromSsnLastName(getPersonInfoFromPersonalNumberLastNameDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify user by population register and find user in crm
     * @param {VerifyContactByNameAndSsnInput} [verifyContactByNameAndSsnInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetPersonFromSsnLastNameAction(verifyContactByNameAndSsnInput?: VerifyContactByNameAndSsnInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetPersonFromSsnLastNameAction(verifyContactByNameAndSsnInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the properties where the logged in person can give an account usage right
     * @param {GetPropertiesWhereUserCanGiveAccountUsageRightInput} [getPropertiesWhereUserCanGiveAccountUsageRightInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetPropertiesWhereUserCanGiveAccountUsageRight(getPropertiesWhereUserCanGiveAccountUsageRightInput?: GetPropertiesWhereUserCanGiveAccountUsageRightInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetPropertiesWhereUserCanGiveAccountUsageRight(getPropertiesWhereUserCanGiveAccountUsageRightInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Takes the accountId of an organization and returns the properties where the organization have right of use
     * @param {NewGetPropertiesWithRightOfUseAccountInput} [newGetPropertiesWithRightOfUseAccountInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetPropertiesWithRightOfUseAccount(newGetPropertiesWithRightOfUseAccountInput?: NewGetPropertiesWithRightOfUseAccountInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetPropertiesWithRightOfUseAccount(newGetPropertiesWithRightOfUseAccountInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the properties owned by the account. Searches first for the info in Matrikkel, then adds information stored in Crm
     * @param {string} propertyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetPropertyInfo(propertyId: string, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetPropertyInfo(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns information about the right of use for a property
     * @param {GetRightOfUseListInputRest} [getRightOfUseListInputRest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetPropertyRightOfUse(getRightOfUseListInputRest?: GetRightOfUseListInputRest, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetPropertyRightOfUse(getRightOfUseListInputRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list over the people who have right to sign for a property
     * @param {GetRightToSignListInput} [getRightToSignListInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetPropertyRightToSign(getRightToSignListInput?: GetRightToSignListInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetPropertyRightToSign(getRightToSignListInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get signed delegation document
     * @param {string} delegationRefCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetSignedDelegationDocumentLink(delegationRefCode: string, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetSignedDelegationDocumentLink(delegationRefCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Getting documents sent to Verified by logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseGetUnsignedDocuments(options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseGetUnsignedDocuments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary For instances where the logged in user wishes to request PowerOfAttorney for a property owned and controlled by someone else
     * @param {RequestPowerOfAttorneyInputRest} [requestPowerOfAttorneyInputRest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseRequestPowerOfAttorney(requestPowerOfAttorneyInputRest?: RequestPowerOfAttorneyInputRest, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseRequestPowerOfAttorney(requestPowerOfAttorneyInputRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Automatically sets this if the 90% rule is ok
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseSetRightOfUseAndPowerOfAttorneyAutomatically(options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseSetRightOfUseAndPowerOfAttorneyAutomatically(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop already started cancel process of RoU
     * @param {StopCancellationRightOfUseInput} [stopCancellationRightOfUseInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RightOfUseApi
     */
    public rightOfUseStopCancellationRightOfUse(stopCancellationRightOfUseInput?: StopCancellationRightOfUseInput, options?: RawAxiosRequestConfig) {
        return RightOfUseApiFp(this.configuration).rightOfUseStopCancellationRightOfUse(stopCancellationRightOfUseInput, options).then((request) => request(this.axios, this.basePath));
    }
}

